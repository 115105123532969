@import '../../styles/colors';

.mainMenuItem {
  width: 48px;
  height: 48px;
  background-color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  .mainMenuItemIcon {
    .active {
      display: none;
    }
    .inactive {
      display: block;
    }

    &:hover {
      .active {
        display: block;
      }
      .inactive {
        display: none;
      }
    }
  }
}
