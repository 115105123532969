@import '../../styles/colors';

.button {
  width: 100%;
  margin-top: 12px;
  font-family: 'Space Grotesk', sans-serif;
  background-color: $cyan;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 900;
  color: $black75;
  &.error {
    background-color: $pink;
  }
}