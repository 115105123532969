@import "../../styles/colors";

.container {
  .label {
    color: $white50;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.5px;
    font-weight: 500;
    margin: 0;
  }

  .input {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .parentsContainer {
    &:hover {
      .removeParentsButton {
        display: block;
      }
    }
    position: relative;
    .removeParentsButton {
      position: absolute;
      right: 0;
      top: 2px;
      display: none;
      height: calc(100% - 4px);
      background-color: $white25;
      cursor: pointer;
      &:hover {
        background-color: $white75;
      }
    }
  }
}
