@import 'styles/colors';

.Resizer {
  background: $cyan;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 0.2s ease;
}

.Resizer.horizontal {
  height: 9px;
  border-top: 4px solid rgba(255, 255, 255, 0);
  border-bottom: 4px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 4px solid rgba(0, 0, 0, 0.5);
  border-bottom: 4px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 9px;
  border-left: 4px solid $cyan10;
  border-right: 4px solid $cyan10;
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 4px solid $cyan50;
  border-right: 4px solid $cyan50;
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}