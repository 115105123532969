@import '../../styles/colors';

.tab {
  display: inline-flex;
  height: 34px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.active {
    background-color: $white10;
    .icon {
      opacity: 0.9;
    }
    .name {
      color: $white90;
    }
  }
  .icon {
    opacity: 0.75;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .name {
    user-select: none;
    color: $white75;
  }
  .close {
    height: 20px;
    width: 20px;
    cursor: pointer;
    background-image: url(./CloseButton.svg);
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 1px;
    &.modified {
      background-image: url(./CloseModified.svg);
      background-size: 10px;
      &:hover {
        background-image: url(./CloseButton.svg);
      }
    }
    &.local {
      background-color: $pink;
    }
    &:hover {
      background-color: $white10;
    }
  }
  & > * + * {
    margin-left: 4px;
  }
}