@import '../styles/colors';

.spinner {
    display: inline-block;
    transform: translateZ(1px);
  div {
    display: inline-block;
    border-radius: 50%;
    animation: spinner 1s cubic-bezier(.68,-0.55,.27,1.55) infinite;
  }
  &.white {
    div {
      background: $white;
    }
  }
  &.cyan {
    div {
      background: $cyan;
    }
  }
  &.black {
    div {
      background: $black;
    }
  }
}
  
@keyframes spinner {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
}