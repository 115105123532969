@import '../styles/colors';

.pinger {
  position: relative;
  .indicator {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    &.idle {
      background-color: $white50;
    }
    &.error {
      background-color: $pink;
    }
    &.loading {
      background-color: $yellow;
    }
    &.success {
      background-color: $cyan;
    }
  }
  &:hover {
    .errorMessage {
      display: block;
    }
  }
  .errorMessage {
    display: none;
    color: $pink;
    position: absolute;
    top: -11px;
    background: $black;
    transform: translateY(-100%);
  }
}