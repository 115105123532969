@import '../styles/colors';

.container {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  color: $white90;
  &:hover {
    color: $white;
  }
  &.concept {
    border-left: 2px solid $yellow75;
    background-color: $yellow10;
    &:hover {
      border-left-color: $yellow;
      background-color: $yellow25;
    }
  }
  &.goji {
    border-left: 2px solid $cyan75;
    background-color: $cyan10;
    &:hover {
      border-left-color: $cyan;
      background-color: $cyan25;
    }
  }
  &.post {
    border-left: 2px solid $white75;
    background-color: $white10;
    &:hover {
      border-left-color: $white;
      background-color: $white25;
    }
  }
  &.series {
    border-left: 2px solid $pink75;
    background-color: $pink10;
    &:hover {
      border-left-color: $pink;
      background-color: $pink25;
    }
  }
  &.collection {
    border-left: 2px solid $blue75;
    background-color: $blue10;
    &:hover {
      border-left-color: $blue;
      background-color: $blue25;
    }
  }
  & + .container {
    margin-top: 6px;
  }
}