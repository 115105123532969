@import '../../styles/colors';

.icon {
  width: 12px;
  height: 12px;
  padding: 4px;
  background-color: $cyan;
  cursor: pointer;
}
.overlay {
  position: fixed;
  z-index: 2;
  inset: 0px;
  background: linear-gradient($cyan10, $cyan10), linear-gradient($black90, $black90);
}
.content {
  position: absolute;
  inset: 60px;
  z-index: 2;
  overflow: hidden;
}
