.shell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .main {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    .splitPaneContainer {
      position: relative;
      flex-grow: 1;
      height: 100%;
    }
  }
}