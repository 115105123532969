@import '../../styles/colors';

.gmSHIndent {
  background: $black !important;
}

/* HEADING */

.gmSHHeadingContent,
.gmSHHeadingHashes {
  font-weight: 700;
}

/* CODE BLOCK */

.gmSHCodeBlockOpeningBackticks,
.gmSHCodeBlockOpeningInfoString,
.gmSHCodeBlockContent,
.gmSHCodeBlockClosing {
  background: $pink25;
}

.gmSHCodeBlockOpeningBackticks,
.gmSHCodeBlockOpeningInfoString {
  color: $white75;
}

/* CODE SPAN */

.gmSHCodeSpanOpening,
.gmSHCodeSpanContent,
.gmSHCodeSpanClosing {
  background: $cyan25;
  border-top: 1px solid $cyan;
  border-bottom: 1px solid $cyan;
}
.gmSHCodeSpanOpening {
  border-radius: 4px 0 0 4px;
  border-left: 1px solid $cyan;
}

.gmSHCodeSpanClosing {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid $cyan;
}

/* STRONG */

.gmSHStrongOpening,
.gmSHStrongContent,
.gmSHStrongClosing {
  font-weight: 700;
}

.gmSHStrongOpening,
.gmSHStrongContent,
.gmSHStrongClosing {
  color: $cyan;
}

/* EMPHASIS */

.gmSHEmphasisOpening,
.gmSHEmphasisContent,
.gmSHEmphasisClosing {
  font-style: italic;
}

.gmSHEmphasisOpening,
.gmSHEmphasisContent,
.gmSHEmphasisClosing {
  color: $pink;
}
