@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'Noto Mono';
  src: url('./NotoMono-Regular.ttf')  format('truetype');
}

@import 'styles/colors';

body {
  background-color: $black;
  color: $cyan;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: 'Noto Sans', sans-serif;
  overflow: hidden;
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
}

button {
  font-family: 'Space Grotesk', sans-serif;
  &:disabled {
    background-color: $cyan50;
    cursor: not-allowed;
  }
}

input, button {
  border: none;
}

input {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  padding: 2px 6px;
  background-color: $cyan10;
  color: $white90;
  outline: none;
  width: calc(100% - 12px);
  box-sizing: content-box;
  &::placeholder {
      color: $white50;
  }
  &:disabled {
    color: $white50;
  }
  &:invalid {
    border-color: $pink;
    border-width: 1px;
    border-style: solid;
  }
}

label {
  color: $white50;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding-top: 12px;
  display: block;
  padding-bottom: 2px;
}

button {
  cursor: pointer;
  &.primary, &.secondary, &.disabled {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 24px;
    padding-right: 24px;
    text-transform: uppercase;
    border-radius: 1px;
    font-weight: 500;
  }
  &.primary {
      background: $cyan90;
      box-shadow: 0px 0px 15px 0px $cyan90, 0px 0px 15px 0px $black10 inset;
      color: $black;
      text-shadow: 0px 0px 1px 0px $cyan inset;
      &:focus {
        outline: none;
        animation: glowing 1500ms infinite;
      }
  }
}

div {
  box-sizing: border-box;
}

pre {
  margin-top: 0;
  margin-bottom: 0;
}

@keyframes glowing {
  0% { background-color: $cyan90; box-shadow: 0 0 15px $cyan90; }
  50% { background-color: $cyan; box-shadow: 0 0 20px $cyan; }
  100% { background-color: $cyan90; box-shadow: 0 0 15px $cyan90; }
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $black;
}
