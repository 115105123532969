@import '../styles/colors';

.privacySelector {
  outline: none;
  & &__control{
    min-height: unset;
    border-radius: 0px;
    border-width: 0px;
    cursor: pointer;
    background-color: $cyan10;
    outline: none;
    box-shadow: none;
    &:hover {
      border-width: 0px;
    }
  }
  & &__value-container{
    outline: none;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 6px;
    padding-right: 6px;
  }
  & &__single-value {
    color: $white90;
    margin-left: 0px;
    margin-right: 0px;
  }
  & &__menu {
    border-radius: 0px;
    margin-top: 0px;
  }
  & &__menu-list {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  & &__option {
    cursor: pointer;
    color: $white75;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 14px;
    background: linear-gradient(0deg, $white5 0%, $white10 100%) !important;
    background-color: $black !important;
    border: 1px solid $white10;
    &:hover {
      color: $white90;
    }
  }
  & &__indicators {
    display: none;
  }
}
