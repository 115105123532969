@import "../../styles/colors";

.spinnerContainer {
  display: flex;
  justify-content: center;
  padding: 6px;
}

.noResults, .error {
  color: $white50;
  font-size: 14px;
  padding-left: 6px;
  padding-top: 2px;
}