.container {
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  margin-top: 6px;
  margin-bottom: 6px;
  position: relative;
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
