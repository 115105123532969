@import '../styles/colors';

.container {
  .label {
    color: $white50;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.5px;
    font-weight: 500;
    padding-bottom: 2px;
  }
  .aliasContainer {
    &:hover {
      .removeAliasButton {
        display: block;
      }
    }
    position: relative;
    .removeAliasButton {
      position: absolute;
      right: 0;
      top: 2px;
      display: none;
      height: calc(100% - 4px);
      background-color: $white25;
      cursor: pointer;
      &:hover {
        background-color: $white75;
      }
    }
  }
  .addAliasButton {
    margin-top: 4px;
    width: 100%;
    background-color: $white25;
    &:hover {
      background-color: $white75;
    }
  }
}