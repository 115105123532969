@import '../../styles/colors';

.container {
  color: $white90;
}

.minimap {
  width: 100%;
  border: 1px solid $cyan;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &:hover {
    &::after {
      content: 'Open Map';
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $black50;
    }
  }
}

.label {
  padding-top: 12px;
  color: $white50;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: 500;
  margin: 0;
}
.none {
  font-style: italic;
}
.overlay {
  position: fixed;
  z-index: 2;
  inset: 0px;
  background: linear-gradient($cyan10, $cyan10), linear-gradient($black90, $black90);
}
.content {
  position: absolute;
  inset: 60px;
  z-index: 2;
  overflow: hidden;
}

.newTabIcon {
  display: inline-block;
  width: 16px;
  height: 12px;
  background-image: url(./NewTab.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }

}