@import '../../styles/colors';

.help, .spinnerContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  height: 100%;
  width: 100%;
  .preview {
    height: 100%;
    width: 100%;
    font-family: 'Noto Sans', sans-serif;
    padding: 6px;
    color: $white90;
    overflow: auto;
    * {
      max-width: 100%;
    }
  }
  .sourceContainer {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    .source {
      width: 100%;
      white-space: pre-wrap;
      background: white;
      padding: 1em;
      outline: none;
      margin-top: 0;
      margin-bottom: 0;
      font-family: 'Noto Mono', monospace;
      background-color: $black;
      color: $white;
      box-sizing: border-box;
      word-break: break-word;
    }
  }

  .infoPane {
    height: 100%;
    width: 100%;
    padding: 6px;
    overflow-x: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }
  }
}
