@import '../../styles/colors';
.tabsBar {
  &.active {
    .activePanelMenu {
      display: block;
    }
  }
  overflow-y: auto;
  height: 35px;
  border-bottom: 1px solid $cyan50;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  .tabsScroller {
    width: max-content;
    display: flex;
    padding-left: 31px;
    padding-right: 34px;
  }
  .activePanelMenu {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    background-color: $black;
    .addPanelButton, .removePanelButton {
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 34px;
      cursor: pointer;
      &:hover {
        background: $white10;
      }
    }
  }
}