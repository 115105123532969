$black: #0D011C;
$cyan: #38FBFD;
$blue: #4200FF;
$yellow: #FFF500;
$pink: #FA00FF;
$white: #FFFFFF;
$black90: #0D011CE6;
$cyan90: #38FBFDE6;
$blue90: #4200FFE6;
$yellow90: #FFF500E6;
$pink90: #FA00FFE6;
$white90: #FFFFFFE6;
$black75: #0D011CBF;
$cyan75: #38FBFDBF;
$blue75: #4200FFBF;
$yellow75: #FFF500BF;
$pink75: #FA00FFBF;
$white75: #FFFFFFBF;
$black50: #0D011C80;
$cyan50: #38FBFD80;
$blue50: #4200FF80;
$yellow50: #FFF50080;
$pink50: #FA00FF80;
$white50: #FFFFFF80;
$black25: #0D011C40;
$cyan25: #38FBFD40;
$blue25: #4200FF40;
$yellow25: #FFF50040;
$pink25: #FA00FF40;
$white25: #FFFFFF40;
$black10: #0D011C1A;
$cyan10: #38FBFD1A;
$blue10: #4200FF1A;
$yellow10: #FFF5001A;
$pink10: #FA00FF1A;
$white10: #FFFFFF1A;
$black5: #0D011C0D;
$cyan5: #38FBFD0D;
$blue5: #4200FF0D;
$yellow5: #FFF5000D;
$pink5: #FA00FF0D;
$white5: #FFFFFF0D;