@import "../../styles/colors";

.container {
  cursor: pointer;
  color: $white75;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 14px;
  background: linear-gradient(0deg, $white5 0%, $white10 100%);
  border: 1px solid $white10;
  &:hover {
    color: $white90;
  }
}