@import '../../styles/colors';

.container {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  height: 31px;
  width: 31px;
  justify-content: center;
  align-items: center;
  background-color: $black;
  .button {
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-image: url(./Plus.svg);
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-color: $white10;
    }
  }
  &:hover {
    .contextMenuContainer {
      display: block;
    }
  }
  .contextMenuContainer {
    position: relative;
    z-index: 1;
    height: 31px;
    width: 0px;
    display: none;
    .contextMenu {
      position: absolute;
      top: 31px;
      left: 0px;
      border-color: $cyan75;
      border-width: 1px;
      border-style: solid;
      .contextMenuItem {
        padding: 6px 6px;
        background-color: $black;
        color: $white75;
        cursor: pointer;
        &:hover {
          color: $white90;
          background: linear-gradient($cyan25, $cyan25),
          linear-gradient($black, $black);
        }
      }
      .contextMenuItem + .contextMenuItem  {
        border-top-color: $cyan75;
        border-top-width: 1px;
        border-top-style: solid;
      }
    }
  }
}