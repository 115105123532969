@import '../styles/colors';

.statusBar {
  font-family: 'Space Grotesk', sans-serif;
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 2px;
  padding-bottom: 2px;

  background: $cyan10;
  box-shadow: inset 0px 1px 0px $cyan50;

  .left, .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    & > div {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}