.editorsPanel {
  height: 100%;
  width: 100%;
  opacity: 0.9;
  position: relative;
  display: flex;
  flex-direction: column;

  &.active {
    opacity: 1;
  }
}