@import '../styles/colors';

.loginBox {
  font-family: 'Space Grotesk', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    .logo {
        width: 151px;
    }
    .restrictionInfoBox {
        width: 320px;
        text-align: center;
        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 10px;
            padding-left: 12px;
            padding-right: 12px;
            border-left: 48px solid $cyan;
            border-right: 48px solid $cyan;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 0.05em;
        }
        .content {
            font-size: 14px;
        }
    }
    .loginForm {
        text-align: center;
        .field {
            display: flex;
            padding: 4px;
            align-items: flex-end;
            .labelWrapper {
                width: 20px;
                margin-right: 10px;
            }
            .label {
                float: right;
                color: $cyan;
                text-transform: initial;
                font-size: 16px;
                letter-spacing: normal;
                font-weight: 400;
                padding-top: 0px;
                display: block;
                padding-bottom: 0px;
            }
            .input {
                font-family: 'Space Grotesk', sans-serif;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                min-width: 220px;
            }
        }
        .button {
            margin-top: 16px;
        }
        .errorBox {
            border-width: 1px;
            border-color: $pink;
            border-style: solid;
            margin-top: 16px;
            padding: 6px;
            text-align: center;
            color: $pink90;
        }
    }
}
